.table > tbody > tr > td p,.table > tbody > tr > td a{
  color: black !important;
  font-weight: 700 !important;
  font-size: 14px !important;
}
.fancy{
  color: black !important;
  font-weight: 700 !important;
  
}
.bg-pink-200 {
  background-color: #f48fb1 !important;
}

.bg-blue-300 {
  background-color: #64b5f6 !important;
}
 
.nestedTable {
  background-color: transparent;
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

.m-b-4 {
  margin-bottom: 4px !important;
}

.m-t-0 {
  margin-top: 0 !important;
}
.text-red-900 {
  color: #b71c1c !important;
}
.suspended-bet {
  background-color: rgba(255, 245, 233, 0.65);
  width: 200%;
  height: 100%;
  top: 0;
  left: 0;
  font-size: 13px;
  position: absolute;
  z-index: 10;
  display: flex;
  display: -ms-flex;
  display: -webkit-flex;
  cursor: not-allowed;
  justify-content: center;
  align-items: center;
}

.suspended-event {
  background-color: rgba(255, 245, 233, 0.65);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  font-size: 13px;
  position: absolute;
  z-index: 10;
  display: flex;
  display: -ms-flex;
  display: -webkit-flex;
  cursor: not-allowed;
  justify-content: center;
  align-items: center;
}
.chkLabel {
  position: absolute;
  display: inline-block;
}
.toggle {
  display: inline-table;
}
.radiocls {
  display: inline-table;
  padding: 0px 0px 0px 20px;
}
.ratediv {
  flex: 1 1 100%;
  box-sizing: border-box;
  max-width: 100%;
}
.btn-xs {
  padding: 5px 10px;
  font-size: 14px;
  height: max-content;
  margin: 2px;
}
.nestedTable tr {
  border: #4c4c4c 1px solid;
}
.padintop10 {
  padding-top: 10px;
}

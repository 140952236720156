.table > tbody > tr > td p,.table > tbody > tr > td a{
  color: black !important;
  font-weight: 700 !important;
  font-size: 14px !important;
}

.bg-pink-200 {
  background-color: #f48fb1 !important;
}

.bg-blue-300 {
  background-color: #64b5f6 !important;
}

.bg-pink-50 {
  background-color: #fce4ec !important;
}

.nestedTable {
  background-color: transparent;
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

.m-b-4 {
  margin-bottom: 4px !important;
}

.m-t-0 {
  margin-top: 0 !important;
}

.text-red-900 {
  color: #b71c1c !important;
}

.bet-locked {
  background-color: rgba(255,245,233,.85);
  width: 301%;
  height: 100%;
  left: 0;
  z-index: 10;
  display: flex;
  display: -ms-flex;
  display: -webkit-flex;
  cursor: not-allowed;
  justify-content: center;
  align-items: center;
}

.bet-locked, .more-opt {
  top: 0;
  position: absolute;
}

.table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td {
  padding: 8px;
  position: relative;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid rgba(0,0,0,.12);
  border-bottom: 1px solid rgba(0,0,0,.12);
}
.matero-col{
  flex: 1 1 100%; box-sizing: border-box; max-width: 100%;
}